<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow
      :title="$t('obj_health_records.lbl_create_hr')"
      @onClose="onClose"
    ></HeaderWindow>
    <div class="mt-3 px-3">
      <SelectCareTeam
        :edit="true"
        :owner_clinic_sp="owner_clinic_sp"
        :selected_treatings="selected_treatings"
        :selected_incharge="selected_incharge"
        :selected_supporters="selected_supporters"
        :err_incharge="err_incharge"
        :err_treating="err_treating"
        @selectIncharge="selectIncharge"
        @selectTreating="selectTreating"
        @selectSupporter="selectSupporter"
      />
      <div class="row">
        <div class="col-12 mb-3">
          <label for="" class="robo-16-500 txt-muted mb-2">{{
            $t("obj_health_records.lbl_disease_name")
          }}</label>
          <input
            type="text"
            class="form-control bg-blue-gray"
            v-model="form.diseases_name"
          />
          <small class="text-red" v-if="err_name">* {{ err_name }}</small>
        </div>
        <div class="col-12 mb-3">
          <label for="" class="robo-16-500 txt-muted mb-2">{{
            $t("obj_health_records.lbl_disease_progression")
          }}</label>
          <textarea
            class="form-control bg-blue-gray"
            :placeholder="
              $t('obj_health_records.lbl_how_does_the_disease_progress')
            "
            v-model="form.diseases_stage"
            style="height: 100px"
          ></textarea>
        </div>
        <div class="col-12 mb-3">
          <label
            for=""
            class="robo-20-500 txt-pri my-3 d-flex align-items-center"
          >
            <svg
              class="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 6.5C18 9.53757 15.5376 12 12.5 12C9.46243 12 7 9.53757 7 6.5C7 6.33146 7.00758 6.16468 7.02242 6H4C2.89543 6 2 6.89543 2 8V21C2 22.1046 2.89543 23 4 23H20C21.1046 23 22 22.1046 22 21V8C22 6.89543 21.1046 6 20 6H17.9776C17.9924 6.16468 18 6.33146 18 6.5ZM4.75 17.5C4.33579 17.5 4 17.8358 4 18.25C4 18.6642 4.33579 19 4.75 19H19.25C19.6642 19 20 18.6642 20 18.25C20 17.8358 19.6642 17.5 19.25 17.5H4.75ZM6 15.25C6 14.8358 6.33579 14.5 6.75 14.5H17.25C17.6642 14.5 18 14.8358 18 15.25C18 15.6642 17.6642 16 17.25 16H6.75C6.33579 16 6 15.6642 6 15.25Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17 6.5C17 8.98528 14.9853 11 12.5 11C10.0147 11 8 8.98528 8 6.5C8 4.01472 10.0147 2 12.5 2C14.9853 2 17 4.01472 17 6.5ZM11.6208 4.75C11.6208 4.33579 11.9566 4 12.3708 4C12.785 4 13.1208 4.33579 13.1208 4.75V6.38818L14.6389 6.1404C15.0477 6.07367 15.4332 6.35099 15.5 6.75979C15.5667 7.1686 15.2894 7.55409 14.8806 7.62081L12.8717 7.94867C12.7928 7.96155 12.7147 7.96161 12.6396 7.95037C12.5562 7.98243 12.4655 8 12.3708 8C11.9566 8 11.6208 7.66421 11.6208 7.25V4.75Z"
                fill="#20419B"
              />
            </svg>
            {{ $t("obj_health_records.lbl_current_status") }}</label
          >
          <textarea
            class="form-control bg-blue-gray"
            v-model="form.diseases_current_cure"
            style="height: 200px"
          ></textarea>
        </div>
        <div class="col-12 mb-3">
          <label
            for=""
            class="robo-20-500 txt-pri my-3 d-flex align-items-center"
          >
            <svg
              class="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.2125 22.5H4.3875H7.10625H10.9125C11.5133 22.5 12 22.8356 12 23.25C12 23.6644 11.5133 24 10.9125 24H2.2125C1.61166 24 1.125 23.6644 1.125 23.25C1.125 22.8356 1.61166 22.5 2.2125 22.5Z"
                fill="#20419B"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.6228 8.65593C19.9423 8.2054 19.836 7.58122 19.3855 7.26177L14.491 3.7913C14.0405 3.47185 13.4163 3.57811 13.0969 4.02864L7.31277 12.1861L19.0444 9.47167L19.6228 8.65593ZM6.44515 13.4097L6.15594 13.8176C5.8365 14.2681 5.94275 14.8923 6.39328 15.2117L11.2877 18.6822C11.7383 19.0017 12.3625 18.8954 12.6819 18.4449L18.1768 10.6953L6.44515 13.4097Z"
                fill="#20419B"
              />
              <rect
                x="17.0422"
                y="1.92236"
                width="4"
                height="5"
                rx="1"
                transform="rotate(35.3389 17.0422 1.92236)"
                fill="#20419B"
              />
              <path
                d="M10.4571 13.8026C10.6169 13.5774 10.9289 13.5243 11.1542 13.684C11.3795 13.8437 11.4326 14.1558 11.2729 14.3811L5.05028 23.1569C4.89056 23.3822 4.57846 23.4353 4.3532 23.2756C4.12794 23.1159 4.07481 22.8038 4.23453 22.5785L10.4571 13.8026Z"
                fill="#20419B"
              />
              <rect
                x="22.5151"
                y="4.57715"
                width="2"
                height="8"
                rx="1"
                transform="rotate(125.339 22.5151 4.57715)"
                fill="#20419B"
              />
            </svg>
            {{ $t("obj_health_records.lbl_treatment") }}</label
          >
          <textarea
            class="form-control bg-blue-gray"
            v-model="form.diseases_previous_cure"
            style="height: 200px"
          ></textarea>
        </div>
        <div class="col-12 mb-3">
          <label
            for=""
            class="robo-20-500 txt-pri my-3 d-flex align-items-center"
          >
            <svg
              class="mr-2"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14272_101952)">
                <path
                  d="M6.75366 7.49268C7.16788 7.49268 7.50366 7.15689 7.50366 6.74268C7.50366 6.32846 7.16788 5.99268 6.75366 5.99268C6.33945 5.99268 6.00366 6.32846 6.00366 6.74268C6.00366 7.15689 6.33945 7.49268 6.75366 7.49268Z"
                  fill="#20419B"
                />
                <path
                  d="M2.625 14.7188H14.25V16.5938C14.25 17.2151 13.7464 17.7188 13.125 17.7188H3.75C3.12863 17.7188 2.625 17.2151 2.625 16.5938V14.7188Z"
                  fill="#E3FCFD"
                />
                <path
                  d="M21.1168 1.29272L22.7079 2.88385C23.1474 3.32335 23.1474 4.03547 22.7079 4.47497L17.5741 9.60872C17.1346 10.0482 16.4225 10.0482 15.983 9.60872L14.3919 8.0176C13.9524 7.5781 13.9524 6.86597 14.3919 6.42647L19.5256 1.29272C19.9651 0.853598 20.6776 0.853598 21.1168 1.29272Z"
                  fill="#20419B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.9837 2.07764L23.7787 1.27889C24.0693 0.986387 24.0693 0.513887 23.7787 0.221387C23.4862 -0.0692383 23.0137 -0.0692383 22.7212 0.221387L21.9224 1.01639L21.6599 0.750137C20.9279 0.0181367 19.7407 0.0181367 19.0087 0.750137L13.8749 5.88389C13.1429 6.61589 13.1429 7.80314 13.8749 8.53514L15.4649 10.1251C16.1969 10.8571 17.3842 10.8571 18.1162 10.1251L20.1299 8.10764L21.2324 7.00889L23.2499 4.99139C23.9819 4.25939 23.9819 3.07214 23.2499 2.34014L22.9837 2.07764ZM17.0549 9.06764L22.1887 3.93014C22.3342 3.78389 22.3342 3.54764 22.1887 3.40139L20.5987 1.81139C20.4524 1.66589 20.2162 1.66589 20.0699 1.81139L14.9324 6.94514C14.7869 7.09139 14.7869 7.32764 14.9324 7.47389L16.5224 9.06764L16.5247 9.06989C16.6717 9.21576 16.9091 9.21464 17.0549 9.06764Z"
                  fill="#20419B"
                />
                <path
                  d="M4.875 23.25C4.875 23.6644 5.21062 24 5.625 24H18C18.4144 24 18.75 23.6644 18.75 23.25C18.75 22.8356 18.4144 22.5 18 22.5H5.625C5.21062 22.5 4.875 22.8356 4.875 23.25Z"
                  fill="#20419B"
                />
                <path
                  d="M3 7.5H3.82875C3.924 7.97025 4.11 8.41725 4.37625 8.81625L3.975 9.21375C3.68213 9.50663 3.68213 9.98212 3.975 10.275C4.26788 10.5679 4.74338 10.5679 5.03625 10.275L5.44125 9.87C5.83763 10.1359 6.28237 10.3219 6.75 10.4175V11.625C6.75 12.0394 7.08563 12.375 7.5 12.375C7.91437 12.375 8.25 12.0394 8.25 11.625V10.425C8.72025 10.3297 9.16725 10.1438 9.56625 9.8775L9.975 10.275C10.2679 10.5679 10.7434 10.5679 11.0363 10.275C11.3291 9.98212 11.3291 9.50663 11.0363 9.21375L10.6313 8.80875C10.8968 8.412 11.0828 7.96763 11.1788 7.5H11.25C11.6644 7.5 12 7.16437 12 6.75C12 6.33563 11.6644 6 11.25 6H11.175C11.0797 5.53088 10.8938 5.085 10.6275 4.6875L11.4788 3.83625C11.7716 3.54337 11.7716 3.06788 11.4788 2.775C11.1859 2.48213 10.7104 2.48213 10.4175 2.775L9.57 3.61875C9.16988 3.35175 8.72137 3.16575 8.25 3.07125V1.875C8.25 1.46062 7.91437 1.125 7.5 1.125C7.08563 1.125 6.75 1.46062 6.75 1.875V3.07125C6.28088 3.1665 5.835 3.3525 5.4375 3.61875L5.4075 3.58875C5.1 3.31125 4.62562 3.33525 4.34812 3.64275C4.08975 3.92887 4.0905 4.36462 4.35 4.65L4.38 4.68C4.11263 5.07975 3.92663 5.52825 3.8325 6H3C2.58562 6 2.25 6.33563 2.25 6.75C2.25 7.16437 2.58562 7.5 3 7.5ZM7.5 5.625C7.91437 5.625 8.25 5.28937 8.25 4.875V4.63125C9.14813 4.94888 9.74888 5.7975 9.75 6.75C9.75075 7.08638 9.675 7.41825 9.52875 7.72125L9.375 7.54125C9.06038 7.272 8.58675 7.30837 8.3175 7.623C8.07713 7.90387 8.07713 8.31787 8.3175 8.59875L8.49 8.77125C8.18212 8.92275 7.84312 9.00112 7.5 9C6.25725 8.99587 5.25337 7.98563 5.25712 6.74288C5.26012 5.79488 5.85713 4.95038 6.75 4.63125V4.875C6.75 5.28937 7.08563 5.625 7.5 5.625Z"
                  fill="#20419B"
                />
                <path
                  d="M7 20.9096H6.25C6.25 21.1946 6.41154 21.455 6.6669 21.5816L7 20.9096ZM21.2645 6.61577C21.0523 6.26004 20.5919 6.14369 20.2361 6.3559C19.8804 6.56811 19.7641 7.02851 19.9763 7.38423L21.2645 6.61577ZM7.75 17.5C7.75 17.0858 7.41421 16.75 7 16.75C6.58579 16.75 6.25 17.0858 6.25 17.5H7.75ZM21.25 12C21.25 17.087 16.9906 21.25 11.6875 21.25V22.75C17.7752 22.75 22.75 17.9586 22.75 12H21.25ZM11.6875 21.25C10.117 21.25 8.63766 20.8843 7.3331 20.2376L6.6669 21.5816C8.1751 22.3292 9.88211 22.75 11.6875 22.75V21.25ZM19.9763 7.38423C20.7869 8.74303 21.25 10.3186 21.25 12H22.75C22.75 10.0386 22.2088 8.1987 21.2645 6.61577L19.9763 7.38423ZM7.75 20.9096V17.5H6.25V20.9096H7.75Z"
                  fill="#20419B"
                />
                <path
                  d="M2 16C2 14.8954 2.89543 14 4 14H13C14.1046 14 15 14.8954 15 16C15 17.1046 14.1046 18 13 18H4C2.89543 18 2 17.1046 2 16Z"
                  fill="#20419B"
                />
                <path
                  d="M0 14.75C0 14.3358 0.335786 14 0.75 14H16.25C16.6642 14 17 14.3358 17 14.75C17 15.1642 16.6642 15.5 16.25 15.5H0.75C0.335786 15.5 0 15.1642 0 14.75Z"
                  fill="#20419B"
                />
              </g>
              <defs>
                <clipPath id="clip0_14272_101952">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>

            {{ $t("obj_health_records.lbl_other_diseases") }}
          </label>
          <textarea
            class="form-control bg-blue-gray"
            v-model="form.background_diseases"
            style="height: 200px"
          ></textarea>
        </div>
      </div>
      <div>
        <SettingStats
          @changeSStats="changeSStats"
          @changeCurrSymp="changeCurrSymp"
          @changeSymp="changeSymp"
          :template="template"
        />
      </div>
      <div
        class="col-12 text-center mt-3"
        v-if="person && can('create-careplan') && can('assign-careteam')"
      >
        <button
          class="btn bg-pri bd-pri text-white px-5"
          @click="onSubmit()"
          :disabled="loading"
        >
          {{ $t("obj_health_records.lbl_create") }}
          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            v-if="loading"
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
import SelectCareTeam from '../../components/Diseases/SelectCareTeam'
import SettingStats from '../../components/Diseases/SettingStats.vue'
import i18n from '../../lang'
import appUtils from '../../utils/appUtils'
import vSelect from 'vue-select'
const cycle_units = [
  { id: 2, text: i18n.t('obj_health_records.lbl_day') },
  { id: 1, text: i18n.t('obj_health_records.lbl_hours') },
  { id: 3, text: i18n.t('obj_health_records.lbl_week') },
  { id: 4, text: i18n.t('obj_health_records.lbl_month') }
]
export default {
  name: 'CreateDiseaseWithTemplate',
  components: { HeaderWindow, SelectCareTeam, vSelect, SettingStats },
  data () {
    return {
      loading: false,
      person: null,
      template: [],
      selected_treatings: [],
      selected_incharge: null,
      selected_supporters: [],
      owner_clinic_sp: [],
      err_incharge: '',
      err_treating: '',
      form: {
        person_id: '',
        person_diseases_id: '',
        diseases_name: '',
        diseases_stage: '',
        diseases_current_cure: '',
        diseases_previous_cure: '',
        background_diseases: '',
        treatment_summary: ''
      },
      err_name: '',
      cycle_units,
      survival_stats: [],
      symptoms: [],
      curr_symptoms: [],
      search_stat: '',
      stats: [],
      curr_survival_stats: []
    }
  },
  watch: {},
  mounted () {
    if (this.$user.doctor.is_verified !== 4) {
      this.$toast.open({
        message: this.$t('obj_health_records.lbl_authentication_when_confirm'),
        type: 'warning'
      })
      this.$router.push({ name: 'DoctorSetting' })
    } else {
      this.getPersonDetail()
      this.addMeToCareTeam()
      if (this.$route?.params?.tempid) {
        this.getTemplate()
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  methods: {
    addMeToCareTeam () {
      let self = this
      let user = appUtils.getLocalUser()
      if (user?.doctor?.type) {
        self.selected_supporters.push(user?.doctor)
      } else {
        self.selected_treatings.push(user?.doctor)
      }
    },
    changeSymp (data) {
      this.symptoms = data
    },
    changeCurrSymp (data) {
      this.curr_symptoms = data
    },
    changeSStats (data) {
      this.survival_stats = data
    },
    onClose () {
      let self = this
      self.$router.push({ path: `/doctor/patient-diseases` }).catch((_) => {})
    },
    async getTemplate () {
      let self = this
      await this.$rf
        .getRequest('DoctorRequest')
        .showTemplate(self.$route.params.tempid)
        .then((res) => {
          self.template = res.data
          self.form.diseases_name = self.template.diseases_name
          // self.form.diseases_stage = self.template.diseases_stage
        })
    },
    async getPersonDetail () {
      let self = this
      try {
        await self.$rf
          .getRequest('DoctorRequest')
          .getPersonDetail(self.$route?.params?.id)
          .then((res) => {
            self.person = res.data
            self.form.person_id = self.person.id
          })
          .then(() => {})
      } catch (e) {
        console.log(e)
      }
    },
    selectIncharge (clinic_doctor) {
      let self = this
      let doctor = clinic_doctor.doctor
      self.err_incharge = ''
      if (!clinic_doctor || !clinic_doctor.doctor) return
      // check trùng bs chính
      if (
        !!self.selected_incharge &&
        clinic_doctor.doctor.id === self.selected_incharge.id
      ) {
        self.selected_incharge = null
      } else {
        // check trùng bs điều trị
        self.selected_incharge = clinic_doctor.doctor
        let treatingIndex = self.selected_treatings
          ? self.selected_treatings.findIndex((dr) => dr.id === doctor.id)
          : -1
        if (treatingIndex > -1) {
          self.selected_treatings.splice(treatingIndex, 1)
        }
        // check trùng hỗ trợ
        let spIndex = self.selected_supporters
          ? self.selected_supporters.findIndex(
            (dr) => dr.id === clinic_doctor.doctor.id
          )
          : -1
        if (spIndex > -1) {
          self.selected_supporters.splice(spIndex, 1)
        }
      }
    },
    selectTreating (clinic_doctor) {
      let self = this
      let doctor = clinic_doctor.doctor
      self.err_treating = ''
      // check trùng bs chính
      if (
        !!self.selected_incharge &&
        clinic_doctor.doctor.id === self.selected_incharge.id
      ) {
        self.selected_incharge = null
      }
      // check trùng bs điều trị
      let treatingIndex = self.selected_treatings
        ? self.selected_treatings.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (treatingIndex > -1) {
        self.selected_treatings.splice(treatingIndex, 1)
      } else {
        self.selected_treatings.push(doctor)
      }
      // check trùng hỗ trợ
      let spIndex = self.selected_supporters
        ? self.selected_supporters.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (spIndex > -1) {
        self.selected_supporters.splice(spIndex, 1)
      }
    },
    selectSupporter (doctor) {
      let self = this
      // check trùng bs chính
      if (!!self.selected_incharge && doctor.id === self.selected_incharge.id) {
        self.selected_incharge = null
      }
      // check trùng bs điều trị
      let treatingIndex = self.selected_treatings
        ? self.selected_treatings.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (treatingIndex > -1) {
        self.selected_treatings.splice(treatingIndex, 1)
      }
      let slt = self.selected_supporters
        ? self.selected_supporters.findIndex((dr) => dr.id === doctor.id)
        : -1
      if (slt > -1) {
        self.selected_supporters.splice(slt, 1)
      } else {
        self.selected_supporters.push(doctor)
      }
    },
    canChangeSupport () {
      return true
      // if (!this.disease) return true
      // if (!this.current_incharge && !this.current_treating) return false
      // let user = appUtils.getLocalUser()
      // return user.doctor && ((!!this.current_incharge && this.current_incharge.id === user.doctor.id) || (!!this.current_treating && this.current_treating.id === user.doctor.id))
    },
    async onSubmit () {
      let self = this
      if (!self.form.diseases_name) {
        self.err_name = this.$t('obj_health_records.lbl_please_enter_name')
        return false
      } else {
        self.loading = true
        await self
          .onSaveDiseases()
          .then((resp_id) => {
            self.loading = false
            if (resp_id) {
              self.finishUpdate(resp_id)
            } else {
              self.$router.push({ path: `/doctor/diseases` })
            }
          })
          .catch((e) => {
            self.loading = false
          })
      }
    },
    validate () {},
    finishUpdate (id) {
      let self = this
      self.$router.push({ path: `/doctor/diseases/${id}` }).catch((_) => {})
    },
    async onSaveDiseases () {
      let self = this
      let params = self.form
      if (self.selected_incharge) params.doctor_id = self.selected_incharge.id
      let resp = await self.$rf
        .getRequest('DoctorRequest')
        .personsDiseasesCreate(params)
        .catch((e) => {
          self.loading = false
        })
      await self.onSaveStats(resp.data.id)
      await self.onSaveSymptoms(resp.data.id)
      if (self.canChangeSupport()) await self.updateCareTeam(resp.data.id)
      return resp.data.id
    },
    async updateCareTeam (pdid, ctid) {
      let self = this
      let params = {}
      let clinic_id = this.$clinicId
      if (self.selected_incharge) {
        params.main_doctor = {
          doctor_id: self.selected_incharge.id,
          clinic_id: clinic_id
        }
      }
      let treaters = []
      let selected_treatings = self.selected_treatings
      if (selected_treatings && selected_treatings.length) {
        selected_treatings.forEach((sp) => {
          treaters.push({
            doctor_id: sp.id,
            clinic_id: clinic_id
          })
        })
      }
      if (treaters) {
        params.follow_doctors = treaters
      }

      let supporters = []
      let selected_supporters = self.selected_supporters
      if (selected_supporters && selected_supporters.length) {
        selected_supporters.forEach((sp) => {
          supporters.push({
            doctor_id: sp.id,
            clinic_id: clinic_id
          })
        })
      }
      if (supporters) {
        params.supporter_doctors = supporters
      }
      let has_me = self.hasMe()
      if (!has_me) {
        let user = appUtils.getLocalUser()
        if (user?.doctor.type) {
          params.supporter_doctors.push({
            doctor_id: user.doctor.id,
            clinic_id: clinic_id
          })
        } else {
          params.follow_doctors.push({
            doctor_id: user.doctor.id,
            clinic_id: clinic_id
          })
        }
      }
      try {
        if (!ctid) {
          params.person_diseases_id = pdid
          return self.createCareTeam3(params)
        } else {
          return self.updateCareTeam3(ctid, params)
        }
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    async updateCareTeam3 (id, params) {
      let self = this
      console.log('updateCareTeam3', params)
      await self.$rf
        .getRequest('DoctorRequest')
        .updateCareTeam3(id, params)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          self.loading = false
        })
    },
    async createCareTeam3 (params) {
      let self = this
      await self.$rf
        .getRequest('DoctorRequest')
        .createCareTeam3(params)
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          self.loading = false
        })
    },
    async onSaveSymptoms (id) {
      let self = this
      // add
      if (!self.curr_symptoms.length && !self.symptoms.length) return
      let new_symptoms = []
      self.curr_symptoms.map((symp) => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        new_symptoms.push(symp)
      })
      self.symptoms.map((symp) => {
        if (symp.is_monitor) {
          symp.is_monitor = 1
        } else {
          symp.is_monitor = 0
        }
        symp.person_id = self.person.id
        symp.person_diseases_id = id
        new_symptoms.push(symp)
      })
      new_symptoms = new_symptoms.filter(
        (item) => item.symptom_name !== '' && item.symptom_name !== null
      )
      if (new_symptoms.length > 0) {
        let params = { symptoms: new_symptoms }
        await self.$rf.getRequest('DoctorRequest').postSymtomps(params)
      }
    },
    async onSaveStats (id) {
      let self = this
      let new_stats = []
      self.survival_stats.map((stat) => {
        let new_stat = {
          code: stat.code,
          person_diseases_id: id,
          min_threshold: parseFloat(stat.min_threshold),
          max_threshold: parseFloat(stat.max_threshold),
          cycle: stat.cycle,
          cycle_unit: stat.cycle_unit,
          unit: stat.unit,
          is_monitor: stat.is_monitor ? 1 : 0
        }
        new_stats.push(new_stat)
      })
      if (new_stats.length > 0) {
        await self.$rf
          .getRequest('DoctorRequest')
          .multiSurvivalStatSetting(id, { params: new_stats })
      }
    },
    hasMe () {
      let self = this
      let user = appUtils.getLocalUser()
      let has_this_id = false
      let dr_id = -1
      if (user.doctor && user.doctor.id) {
        dr_id = user.doctor.id
      }
      if (
        (self.selected_incharge && dr_id === self.selected_incharge.id) ||
        (self.current_incharge && dr_id === self.current_incharge.id)
      ) {
        has_this_id = true
      } else if (
        (self.selected_treatings && dr_id === self.selected_treatings.id) ||
        (self.current_treatings && dr_id === self.current_treatings.id)
      ) {
        has_this_id = true
      } else if (self.selected_supporters && self.selected_supporters.length) {
        let is_sp = !!self.selected_supporters.find((dr) => dr.id === dr_id)
        if (is_sp) {
          has_this_id = true
        }
      } else if (self.current_supporters && self.current_supporters.length) {
        let is_sp = !!self.current_supporters.find((dr) => dr.id === dr_id)
        if (is_sp) {
          has_this_id = true
        }
      }
      return has_this_id
    }
  }
}
</script>
<style lang="css" scoped>
</style>