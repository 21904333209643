<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <div class="page-top d-flex mt-3">
      <div class="header-title"></div>
      <div class="header-icon">
        <img
          class="cursor-pointer"
          src="../../../public/assets/images/icon/x-gray.svg"
          alt=""
          width="32"
          height="32"
          @click="onClose()"
        />
      </div>
    </div>
    <FormPhieuTuVan v-if="appt" :appt="appt" :is_create="true"></FormPhieuTuVan>
  </div>
</template>
<script>
import FormPhieuTuVan from '../../components/PhieuTuVan/FormPhieuTuVan'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
export default {

  name: 'ConsultationCreate',
  components: { FormPhieuTuVan, HeaderWindow },
  data () {
    return {
      title: 'Phiếu tư vấn',
      appt: null,
      appt_id: null,
      hr: null
    }
  },
  mounted () {
    this.appt_id = this.$route.query.aid && !isNaN(this.$route.query.aid) ? parseInt(this.$route.query.aid) : null
    this.getAppts()
  },
  methods: {
    async getAppts () {
      let self = this
      if (!this.$route.query.aid) return
      try {
        await self.$rf.getRequest('DoctorRequest').getAppts(this.$route.query.aid).then(resp => {
          if (resp && resp.data) {
            self.appt = resp.data
          } else {
            self.$router.push({ path: '/doctor/appointment-management' })
          }
        })
      } catch (e) {
        // statements
        console.log(e)
      } finally { }
    },
    onClose () {
      if (this.$preRoutes.length > 0 && this.$preRoutes[this.$preRoutes.length - 1] !== 'ConsultationCreate') {
        this.$router.go(-1)
      } else {
        if (this.consultation?.appointment) {
          this.$router.push({name: 'AppointmentDetail', params: {id: this.consultation.appointment.id}})
        } else {
          this.$router.push({name: 'HomePage'})
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
</style>