<template>
    <div class="container mt-3 border bd-e4">
         <div class="page-top d-flex mb-3 mt-3">
            <div class="header-title">
                <p class="fs-24 fw-500 mb-0 mb-24">Phiếu tư vấn</p>
            </div>
            <div class="header-icon">
                <img class="cursor-pointer" src="../../../public/assets/images/icon/x-gray.svg" alt="" width="32" height="32" @click="goBack()">
            </div>
        </div>
        <div class="d-flex justify-content-end my-3 align-items-center">
          <a href="javascript:;" @click="goToConsu" class="fs-16 fw-700 txt-pri mr-4">Chỉnh sửa</a>
          <img src="../../../public/assets/images/icon/Icon-hisotry.svg" alt="" height="24" class="cursor-pointer" @click="goToHis()">
        </div>
        <div class="p-4">
          <PhieuTuVan v-if="consultation" :form="consultation" :person="consultation.appointment.person" :view="true"></PhieuTuVan>
        </div>
    </div>
</template>
<script>
import PhieuTuVan from '../../templates/PhieuTuVan/PhieuTuVan.vue'
export default {

  name: 'ConsultationUpdate',
  components: { PhieuTuVan },
  data () {
    return {
      // title: 'Phiếu tư vấn',
      consultation: null
    }
  },
  mounted () {
    this.getConsultationDetail()
  },
  methods: {
    async getConsultationDetail () {
      let self = this
      if (!self.$route.params.id) return
      await self.$rf.getRequest('DoctorRequest').getConsultationDetail(self.$route.params.id).then(res => {
        if (res && res.data) {
          self.consultation = res.data
        }
      })
    },
    onClose () {
      if (!this.disease) { this.$router.push({ path: `/doctor/doctor/patient-diseases` }) } else { this.$router.push({ path: `/doctor/diseases/${this.disease?.id}/activity` }) }
    },
    goToHis () {
      this.$router.push({path: `/doctor/consultation/${this.$route.params.id}/history`})
    },
    getGender (gender = 1) {
      return gender === 1 ? 'Nam' : gender === 2 ? 'Nữ' : gender === 3 ? 'Khác' : ''
    },
    goToConsu () {
      if (!this.consultation?.appointment) return
      this.$router.push({path: `/doctor/consultation-create?aid=${this.consultation?.appointment?.id}`})
    },
    goBack () {
      console.log(this.$preRoutes)
      if (this.$preRoutes.length) {
        if (this.$preRoutes[this.$preRoutes.length - 1] && this.$preRoutes[this.$preRoutes.length - 1] !== 'ConsultationCreate') {
          this.$router.go(-1)
        } else {
          if (this.$preRoutes.length > 1 && this.$preRoutes[this.$preRoutes.length - 2] !== 'ConsultationUpdate') {
            this.$router.go(-2)
          } else {
            if (this.consultation?.appointment) {
              this.$router.push({name: 'AppointmentDetail', params: {id: this.consultation.appointment.id}})
            } else {
              this.$router.push({name: 'HomePage'})
            }
          }
        }
      } else {
        if (this.consultation?.appointment) {
          this.$router.push({name: 'AppointmentDetail', params: {id: this.consultation.appointment.id}})
        } else {
          this.$router.push({name: 'HomePage'})
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
.header-title { 
  border-bottom: 1px solid #DFDFDF; 
}
</style>