import { render, staticRenderFns } from "./ConsultationUpdate.vue?vue&type=template&id=b0b454ea&scoped=true"
import script from "./ConsultationUpdate.vue?vue&type=script&lang=js"
export * from "./ConsultationUpdate.vue?vue&type=script&lang=js"
import style0 from "./ConsultationUpdate.vue?vue&type=style&index=0&id=b0b454ea&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0b454ea",
  null
  
)

export default component.exports